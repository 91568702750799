<template>
  <div>
    <div class="text-h5 pb-4 font-weight-bold primary--text text-center header">
      Welcome to Oribalt track & trace system
    </div>
    <v-row class="truck-img-container" justify="center" no-gutters>
      <div class="truck-img" />
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
.truck-img-container {
  background-color: #ead2c3;
}
.truck-img {
  background-image: url("../assets/truck.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  height: 426px;
  width: 1000px;
}
.header {
  letter-spacing: 2px !important;
}
</style>
