const download = (data, fileName = "download.xlsx") => {
  const link = document.createElement("a");
  link.style.display = "none";
  document.body.appendChild(link);

  const blob = new Blob([data], { type: "application/octet-binary" });
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

const formatTimeFromSeconds = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24));
  const hours = Math.floor((seconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";

  if (days > 0) {
    formattedTime += days + "d ";
  }

  if (hours > 0) {
    formattedTime += hours + "h ";
  }

  if (minutes > 0) {
    formattedTime += minutes + "m ";
  }

  if (remainingSeconds > 0 || formattedTime === "") {
    formattedTime += remainingSeconds + "s";
  }

  return formattedTime.trim();
};

const getOrderStatus = (statusCode) => {
  const OrderStatuses = [
    "Created",
    "Picking started",
    "Ready for delivery",
    "Shipped",
    "Partially delivered",
    "Delivered",
    "Self pick up",
    "Closed",
  ];
  if (statusCode < 1 || statusCode > OrderStatuses.length) return "Invalid";
  return OrderStatuses[statusCode - 1];
};

const getIcon = (fileName) => {
  if (/\.(csv|dbf|dif|ods|prn|slk|xl(a|am|s|sb|sm|sx|w))$/.test(fileName))
    return "mdi-file-excel-outline";
  if (/\.(doc(m|x)?|odt|rtf)$/.test(fileName)) return "mdi-file-word-outline";
  if (/\.txt$/.test(fileName)) return "mdi-file-document-outline";
  if (/\.pdf$/.test(fileName)) return "mdi-file-pdf-outline";
  return "mdi-file-outline";
};

const trimFileName = (fileName, maxLength = 24) => {
  if (fileName?.length > maxLength)
    return String(fileName).substring(0, maxLength) + "..";
  else return String(fileName);
};

const validationRules = {
  required: [
    (v) =>
      (typeof v === "string" && v.trim().length > 0) ||
      typeof v === "number" ||
      "Required",
  ],
  fullName: [
    (v) => (typeof v === "string" && v.trim().length > 0) || "Required",
    (v) => (typeof v === "string" && v.trim().length < 255) || "Too long",
  ],
  loginName: [
    (v) => !!v || "Required",
    (v) =>
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Must be a valid email",
  ],
  password: [
    (v) => !!v || "Required",
    (v) => v?.length > 7 || "Too short",
    (v) => v?.length < 255 || "Too long",
    (v) => /[\d]+/.test(v) || "Must include a number",
    (v) => /[A-ž]+/.test(v) || "Must include a letter",
  ],
  passwordShort: [
    (v) => !!v || "Required",
    (v) => v?.length > 5 || "Too short",
    (v) => v?.length < 255 || "Too long",
    (v) => /[\d]+/.test(v) || "Must include a number",
    (v) => /[A-ž]+/.test(v) || "Must include a letter",
  ],
  positiveNumbers: [(v) => /^\d+$/.test(v) || "Only positive integers"],
  phone: [(v) => /^\+?[0-9]{5,255}$/g.test(v) || "At least 5 digits"],
};

const claimStates = {
  1: "Created",
  2: "Pending",
  3: "Closed",
};

export {
  claimStates,
  download,
  formatTimeFromSeconds,
  getIcon,
  getOrderStatus,
  trimFileName,
  validationRules,
};
