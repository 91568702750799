import SettingsService from "../services/settings.service";

const localSettings = JSON.parse(localStorage.getItem("settings"));
const initialState = localSettings
  ? localSettings
  : {
      region: {
        text: "lithuania",
        value: "lt",
      },
    };

export const settings = {
  namespaced: true,
  state: initialState,
  actions: {
    setRegion({ commit }, region) {
      SettingsService.saveRegion(region);
      commit("setRegion", region);
    },
  },
  mutations: {
    setRegion(state, region) {
      state.region = region;
    },
  },
  getters: {
    region: (state) => {
      return state.region;
    },
  },
};

export default settings;
