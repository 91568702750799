<template>
  <v-card :loading="loading">
    <v-form ref="changePassForm" @submit.prevent="changePassword">
      <v-card-title class="justify-center text-h5 text-capitalize"
        >change password</v-card-title
      >
      <v-card-text class="pb-0">
        <v-row>
          <v-col class="py-0">
            <v-text-field
              :disabled="loading"
              :rules="currentPassword.rules"
              @input="currentPassword.notAccepted = false"
              autocomplete="current-password"
              autofocus
              label="Current Password"
              type="password"
              v-model="currentPassword.value"
            />
            <v-text-field
              :disabled="loading"
              :rules="newPassword.rules"
              autocomplete="new-password"
              label="New Password"
              type="password"
              v-model="newPassword.value"
            />
            <v-text-field
              :disabled="loading"
              :rules="repeated.rules"
              autocomplete="new-password"
              label="Confirm new password"
              type="password"
              v-model="repeated.value"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn @click="$emit('input', false)" color="primary" text>
          Cancel
        </v-btn>
        <v-btn :disabled="loading" type="submit" color="primary" text
          >change</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import AuthService from "../services/auth.service";
import { validationRules } from "../helpers";

export default {
  data() {
    return {
      currentPassword: {
        notAccepted: false,
        rules: [
          ...validationRules.required,
          () => !this.currentPassword.notAccepted || "Password does not match",
        ],
        value: "",
      },
      newPassword: {
        rules: [...validationRules.password],
        value: "",
      },
      repeated: {
        rules: [
          () =>
            this.newPassword.value === this.repeated.value ||
            "New passwords must match",
        ],
        value: "",
      },
      loading: false,
    };
  },
  methods: {
    changePassword() {
      const isValid = this.$refs.changePassForm.validate();
      if (isValid) {
        this.loading = true;

        AuthService.changePassword(
          this.currentPassword.value,
          this.newPassword.value
        )
          .then(() => {
            this.$emit("passwordChanged");
            this.$emit("input", false);
          })
          .catch((err) => {
            if (err.response.status === 406) {
              this.currentPassword.notAccepted = true;
              this.$refs.changePassForm.validate();
            } else {
              console.log(err);
            }
          })
          .then(() => (this.loading = false));
      }
    },
  },
  props: ["value"],
  watch: {
    value(v) {
      if (!v) {
        this.$refs.changePassForm.reset();
      }
    },
  },
};
</script>