<template>
  <v-img
    alt="Company logo"
    contain
    class="shrink"
    :src="
      white
        ? require('../assets/logo-white.png')
        : require('../assets/logo.png')
    "
    transition="scale-transition"
  />
</template>
<script>
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
