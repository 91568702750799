import axios from "axios";
import router from "../router";
import authHeader from "./auth-header";

class AuthService {
  changePassword(currentPassword, newPassword) {
    return axios.post(
      `/auth/changePassword`,
      {
        currentPassword,
        newPassword,
      },
      { headers: authHeader() }
    );
  }

  login(user) {
    return axios
      .post(`/auth/signin`, {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    router.push("/login");
  }

  beginPasswordReset(email) {
    return axios.post(`/auth/reset`, { email });
  }

  completePasswordReset(token, newPassword) {
    return axios.post(`/auth/reset/${token}`, { newPassword });
  }

  register(user) {
    return axios.post(`/auth/signup`, {
      email: user.email,
      password: user.password,
      username: user.username,
    });
  }
}

export default new AuthService();
