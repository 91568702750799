import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "home",
    path: "/",
    component: Home,
  },
  {
    name: "login",
    path: "/login",
    component: () => import("../views/Auth/Login.vue"),
  },
  {
    name: "reset",
    path: "/reset/:token",
    component: () => import("../views/Auth/Reset.vue"),
  },
  {
    name: "orders",
    path: "/orders/:status?",
    component: () => import("../views/Orders.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("orders") || roles.includes("admin")) return next();

      return next("/");
    },
  },
  {
    name: "products",
    path: "/products",
    component: () => import("../views/Products.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("products") || roles.includes("admin")) return next();

      return next("/");
    },
  },
  {
    name: "reports",
    path: "/reports/:type",
    component: () => import("../views/Reports.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("statistics") || roles.includes("admin"))
        return next();

      return next("/");
    },
  },
  {
    name: "received-orders",
    path: "/kpis/received",
    component: () => import("../views/KPIs/ReceivedOrders.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("statistics") || roles.includes("admin"))
        return next();

      return next("/");
    },
  },
  {
    name: "picked-orders",
    path: "/kpis/picked",
    component: () => import("../views/KPIs/PickedOrders.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("statistics") || roles.includes("admin"))
        return next();

      return next("/");
    },
  },
  {
    name: "delivered-orders",
    path: "/kpis/delivered",
    component: () => import("../views/KPIs/DeliveredOrders.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("statistics") || roles.includes("admin"))
        return next();

      return next("/");
    },
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("../views/Invoices.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("invoices") || roles.includes("admin")) return next();

      return next("/");
    },
  },
  {
    name: "claims",
    path: "/claims",
    component: () => import("../views/Claims.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("claims") || roles.includes("admin")) return next();

      return next("/");
    },
  },
  {
    path: "/clients",
    component: () => import("../views/Clients.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("admin")) return next();

      return next("/");
    },
  },
  {
    path: "/clients/:clientCode",
    component: () => import("../views/Client.vue"),
    beforeEnter: (_, __, next) => {
      const { roles } = store.state.auth.user;
      if (roles.includes("admin")) return next();

      return next("/");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const publicRoutes = ["login", "reset"];

  const getStoredUser = () => {
    const storedUser = localStorage.getItem("user");
    const user = JSON.parse(storedUser);
    return user;
  };

  const getCurrentRoles = (user) => {
    if (user && new Date(user.tokenExp) > new Date()) {
      return [...user.roles];
    } else {
      localStorage.removeItem("user");
      return ["anonymous"];
    }
  };

  const user = getStoredUser();
  const userRoles = getCurrentRoles(user);

  if (publicRoutes.includes(to.name) && userRoles.includes("anonymous"))
    return next();
  if (userRoles.includes("anonymous")) return next("/login");

  return next();
});

export default router;
