<template>
  <v-app id="app">
    <v-app-bar
      app
      flat
      height="108"
      v-if="this.$route.name !== 'login' && this.$route.name !== 'reset'"
    >
      <v-col class="pa-0 ma-0">
        <v-row justify="end" no-gutters>
          <v-btn-toggle group class="mr-12 pr-12">
            <v-row align="center" no-gutters>
              <span class="text-uppercase">user:</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="text-uppercase orange--text font-weight-bold"
                    text
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ loginName }}
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="openDialog('settings')">
                    <v-list-item-title class="text-uppercase">
                      settings
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDialog('change_password')">
                    <v-list-item-title class="text-uppercase">
                      change password
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="logout">
                    <v-list-item-title class="text-uppercase">
                      logout
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
          </v-btn-toggle>
        </v-row>

        <v-row
          align="center"
          class="flex-nowrap primary"
          justify="space-around"
          no-gutters
        >
          <router-link to="/">
            <company-logo :white="true" />
          </router-link>

          <v-btn-toggle tile group>
            <v-btn
              class="font-weight-600 text-uppercase white--text"
              text
              to="/products"
              v-if="roles.includes('products') || roles.includes('admin')"
              x-large
            >
              products
            </v-btn>

            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="font-weight-600 text-uppercase white--text"
                  close-on-content-click
                  text
                  to="/orders"
                  v-bind="attrs"
                  v-if="roles.includes('orders') || roles.includes('admin')"
                  v-on="on"
                  x-large
                  >track & trace</v-btn
                >
              </template>
              <v-list>
                <v-list-item to="/orders/inprocess">In Process</v-list-item>
                <v-list-item to="/orders/history">History</v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-if="roles && roles.includes('admin')"
              x-large
              text
              class="font-weight-600 text-uppercase white--text"
              to="/clients"
            >
              clients
            </v-btn>

            <v-btn
              class="font-weight-600 text-uppercase white--text"
              text
              to="/invoices"
              v-if="roles.includes('invoices') || roles.includes('admin')"
              x-large
              >invoices</v-btn
            >

            <v-btn
              class="font-weight-600 text-uppercase white--text"
              text
              to="/claims"
              v-if="roles.includes('claims') || roles.includes('admin')"
              x-large
              >claims</v-btn
            >

            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="font-weight-600 text-uppercase white--text"
                  close-on-content-click
                  text
                  v-bind="attrs"
                  v-if="roles.includes('statistics') || roles.includes('admin')"
                  v-on="on"
                  x-large
                  >reports</v-btn
                >
              </template>
              <v-list>
                <v-list-item to="/reports/deliveries" class="text-uppercase"
                  >deliveries</v-list-item
                >
              </v-list>
            </v-menu>

            <v-menu
              offset-y
              open-on-hover
              v-if="roles.includes('statistics') || roles.includes('admin')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="font-weight-600 text-uppercase white--text"
                  close-on-content-click
                  text
                  v-bind="attrs"
                  v-on="on"
                  x-large
                  >KPI's</v-btn
                >
              </template>
              <v-list>
                <v-list-item to="/kpis/received" class="text-uppercase"
                  >received</v-list-item
                >
                <v-list-item to="/kpis/picked" class="text-uppercase"
                  >picked</v-list-item
                >
                <v-list-item to="/kpis/delivered" class="text-uppercase"
                  >delivered</v-list-item
                >
              </v-list>
            </v-menu>
          </v-btn-toggle>
        </v-row>
      </v-col>
    </v-app-bar>

    <v-main> <router-view /> </v-main>

    <v-dialog max-width="400px" v-model="dialog.show">
      <change-settings v-if="dialog.type == 'settings'" v-model="dialog.show" />

      <change-password
        @passwordChanged="showSnackbar('Password has been changed')"
        v-if="dialog.type == 'change_password'"
        v-model="dialog.show"
      />
    </v-dialog>

    <v-snackbar :color="snackbar.color" v-model="snackbar.show" timeout="5000">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ChangePassword from "./components/ChangePassword.vue";
import ChangeSettings from "./components/ChangeSettings.vue";
import CompanyLogo from "./components/CompanyLogo.vue";

export default {
  name: "App",
  components: {
    ChangePassword,
    ChangeSettings,
    CompanyLogo,
  },
  computed: {
    ...mapGetters("auth", ["loginName"]),
    ...mapGetters("auth", ["roles"]),
  },
  data() {
    return {
      dialog: {
        show: false,
        loading: false,
      },
      snackbar: {
        color: "primary",
        show: false,
        message: "",
      },
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    openDialog(type) {
      this.dialog.type = type;
      this.dialog.show = true;
    },
    showSnackbar(message, color = "primary") {
      this.snackbar = {
        color,
        message,
        show: true,
      };
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

.v-application {
  background-color: var(--v-accent-base) !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.v-toolbar__content {
  padding: 0px !important;
}
</style>
