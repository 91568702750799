<template>
  <v-card>
    <v-form @submit.prevent="changeSettigns">
      <v-card-title class="justify-center text-h5 text-capitalize"
        >Settings</v-card-title
      >
      <v-card-text class="pb-0">
        <v-row>
          <v-col class="py-0">
            <v-switch
              :disabled="loading"
              :loading="loading"
              label="Receive order status update emails"
              v-model="value"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn @click="$emit('input', false)" color="primary" text
          >Cancel</v-btn
        >
        <v-btn
          :disabled="loading || initialValue == value"
          color="primary"
          text
          type="submit"
          >save</v-btn
        >
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import SettingsService from "../services/settings.service";

export default {
  created() {
    SettingsService.getNotifyOrderStatus()
      .then((status) => {
        this.value = this.initialValue = status;
      })
      .catch((err) => console.log(err))
      .then(() => (this.loading = false));
  },
  data() {
    return {
      initialValue: false,
      loading: true,
      value: false,
    };
  },
  methods: {
    changeSettigns() {
      this.loading = true;
      SettingsService.setNotifyOrderStatus(this.value)
        .then(() => {
          this.initialValue = this.value = !this.initialValue;
          this.$emit("input", false);
        })
        .catch((err) => console.log(err))
        .then(() => (this.loading = false));
    },
  },
};
</script>