import axios from "axios";
import authHeader from "./auth-header";

class SettingsService {
  getGreeting() {
    let url = `/settings/greeting`;
    return axios.get(url, { headers: authHeader() });
  }
  getNotifyOrderStatus() {
    let url = `/settings`;
    return axios
      .get(url, { headers: authHeader() })
      .then(({ data }) => data.notifyOrderStatus);
  }
  setNotifyOrderStatus(value) {
    let url = `/settings`;
    return axios.post(
      url,
      { notifyOrderStatus: value },
      { headers: authHeader() }
    );
  }
  saveRegion(region) {
    const localSettings = JSON.parse(localStorage.getItem("settings"));
    const settings = { ...localSettings, region };
    localStorage.setItem("settings", JSON.stringify(settings));
  }
}

export default new SettingsService();
